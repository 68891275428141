//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';

#acf_after_title-sortables {
  margin-bottom: 0 !important;
}

.wp-admin {
	#quick_edit_notice,
	.wcml-pointer-products_translation {
		display: none !important;
	}

	p[class*='synchronisation_variation_'] {
    margin-top: 3px !important;
    margin-bottom: 3px !important;

		input[type='checkbox'] {
			margin-right: 6px !important;
		}

		label[for^='synchronisation_variation_'] {
			margin-top: 2px !important;
			display: inline-block !important;
		}
	}
}
